<template>
  <v-dialog v-model="active" width="600">
    <v-card>
      <v-card-title>{{ chapel._id ? 'Edit' : 'Add' }} Chapel</v-card-title>
      <v-tabs v-model="tab">
        <v-tab>Chapel Details</v-tab>
        <v-tab>Links</v-tab>
        <v-tab>Attendance Stats/Credit Values</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat style="min-height:350px">
            <v-card-text>
              <v-row class="mb-0">
                <v-col cols="6">
                  <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="date" label="Chapel Date" prepend-icon="fal fa-calendar-day" outlined readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable @input="dateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-select v-model="time" :items="timeOptions" label="Chapel Time" prepend-icon="fal fa-clock" outlined></v-select>
                </v-col>
              </v-row>
              <v-text-field v-model="speaker" label="Speaker" prepend-icon="fal fa-podium" outlined></v-text-field>
              <v-text-field v-model="title" label="Title" prepend-icon="fal fa-comment-lines" outlined></v-text-field>
              <v-text-field v-model="series" label="Series" prepend-icon="fal fa-users-class" outlined></v-text-field>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="min-height:350px">
            <v-card-text>
              <v-list>
                <v-list-item v-for="({ type, label, link, visible, loginRequired }, index) in links" :key="index" @click="editLink(index)">
                  <v-list-item-content>
                    <v-list-item-title>Label: {{ label }}</v-list-item-title>
                    <v-list-item-subtitle>Links to: {{ link }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Category: {{ type }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">fal fa-eye{{ visible ? '' : '-slash' }}</v-icon>
                      </template>
                      <span>{{ visible ? 'Is' : 'Is not' }} visible to anyone but admins</span>
                    </v-tooltip>
                  </v-list-item-action>
                  <v-list-item-action v-if="loginRequired">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">fal fa-user{{ loginRequired ? '' : '-slash' }}</v-icon>
                      </template>
                      <span>Login {{ loginRequired ? 'is' : 'is not' }} required</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-dialog v-model="addLinkDialog" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="success">Add Link</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{ editLinkIndex ? 'Edit' : 'Add' }} Link</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col :cols="6" class="pb-0">
                        <v-combobox v-model="newLink.type" :items="linkCategories" label="Link Category" outlined></v-combobox>
                      </v-col>
                      <v-col :cols="6" class="pb-0">
                        <v-text-field v-model="newLink.label" label="Label for link" outlined></v-text-field>
                      </v-col>
                      <v-col :cols="12" class="pt-0 pb-0">
                        <v-text-field v-model="newLink.link" label="Link" outlined hide-details>
                          <template v-if="newLink.link !== ''" v-slot:append-outer>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :href="newLink.link" target="_blank">
                                  <v-icon>fal fa-external-link</v-icon>
                                </v-btn>
                              </template>
                              <span>Open Link</span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col :cols="6" class="pt-0 pb-0">
                        <v-checkbox v-model="newLink.visible" label="Visible to users" outlined></v-checkbox>
                      </v-col>
                      <v-col :cols="6" class="pt-0 pb-0">
                        <v-checkbox v-if="newLink.visible" v-model="newLink.loginRequired" label="Login required to view"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="closeLinkDialog">Cancel</v-btn>
                    <v-btn :disabled="newLink.type === '' || newLink.label === '' || newLink.link === ''" text color="success" @click="addLink">Add Link</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="editLinkIndex != null" text color="error" @click="removeLink">Remove Link</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="min-height:350px">
            <v-data-table :items="statsArr" :headers="[{text:'Credit Type',value:'label'},{text:'Credit Value',value:'value'},{text:'Count',value:'count'},{text:'Action',value:'action'}]" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }">
              <template v-slot:item.value="{ item }">
                <v-btn icon @click="item.value--">
                  <v-icon small>fal fa-minus</v-icon>
                </v-btn>
                {{ item.value }}
                <v-btn icon @click="item.value++">
                  <v-icon small>fal fa-plus</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip v-if="item.count > 0" top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="'/chapel/students/' + chapel._id + '/' + item.label" icon>
                      <v-icon>fal fa-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View all students who had a "{{ item.label }}"" credit for this chapel</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <!-- <v-card-text>
              <v-row v-for="(stat, grouping) in stats" :key="'stat-'+grouping">
                <v-col cols="4">
                  <v-text-field v-model="stat.label" label="Credit Type" class="hideLine" readonly hide-details></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="stat.creditValue" type="number" label="Credit Value" hide-details></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="stat.count" label="Count" class="hideLine" readonly hide-details></v-text-field>
                </v-col>
              </v-row>
            </v-card-text> -->
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions v-if="isSaving">
        <v-alert color="info">Saving...</v-alert>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn text @click="active = false">Cancel</v-btn>
        <v-btn text color="primary" @click="save">Save</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="isComplete" :to="'/chapel/students/' + chapel._id">View Students</v-btn>
        <v-spacer></v-spacer>
        <v-dialog v-if="chapel._id" v-model="removeDialog" width="300">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text color="error">Remove</v-btn>
          </template>
          <v-card>
            <v-card-title>Please Confirm</v-card-title>
            <v-card-text>
              <p>Are you sure you want to remove this chapel?</p>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="removeDialog = false">Cancel</v-btn>
              <v-btn text color="error" @click="remove">Remove Chapel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
div.v-input.v-input--is-readonly.theme--light.v-text-field.hideLine > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0);
}
</style>
<script>
import { ref, watch, computed } from '@vue/composition-api'
import { updateStudentCredits } from './functions'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    chapel: {
      type: Object,
      default: () => { return { date: '', speaker: '', title: '', series: '' } }
    }
  },
  setup (props, { root, emit }) {
    const active = ref(false)
    const tab = ref(0)
    const dateMenu = ref(false)
    const date = ref('')
    const timeOptions = ref([
      { text: '11:00 AM', value: '11:00' },
      { text: '4:00 PM', value: '16:00' },
      { text: '4:30 PM', value: '16:30' },
      { text: '6:00 PM', value: '18:00' },
      { text: '7:00 PM', value: '19:00' },
      { text: '7:30 PM', value: '19:30' },
      { text: '8:00 PM', value: '20:00' }
    ])
    const time = ref('')
    const speaker = ref('')
    const title = ref('')
    const series = ref('')
    const isComplete = computed(() => {
      if (date.value === '' || !('_id' in props.chapel) || props.chapel._id === '') return false
      const dt = new Date(date.value)
      return dt < new Date()
    })
    const links = ref([])
    const linkCategories = ref(['Audio', 'Video', 'Slides', 'Handout', 'Scanner Sheet'])
    const statsArr = ref([
      { label: 'Swipe', value: 1, count: 0 },
      { label: 'Sign-in', value: 1, count: 0 },
      { label: 'Exemption', value: 1, count: 0 },
      { label: 'Summary', value: 1, count: 0 },
      { label: 'Removed', value: 0, count: 0 }
    ])
    watch(() => props.value, () => {
      active.value = props.value
    }, { lazy: true })
    watch(active, () => {
      emit('input', active.value)
    })
    watch(() => props.chapel, async () => {
      if ('date' in props.chapel && props.chapel.date !== '') {
        let dt = new Date(props.chapel.date)
        date.value = dt.toISOString().substring(0, 10)
        time.value = dt.toTimeString().substring(0, 5)
        speaker.value = props.chapel.speaker
        title.value = props.chapel.title
        series.value = props.chapel.series
        links.value = props.chapel.links || []
        const aggregate = [
          { $match: { 'term': props.chapel.term, 'credits.chapel': props.chapel._id } },
          { $unwind: '$credits' },
          { $match: { 'credits.chapel': props.chapel._id } },
          { $group: { _id: '$credits.type', count: { $sum: 1 } } }
        ]
        const { data } = await root.$feathers.service('chapel/student').find({ query: { aggregate } })
        for (let i = 0; i < statsArr.value.length; i++) {
          const { label } = statsArr.value[i]
          const value = 'credits' in props.chapel && label in props.chapel.credits ? props.chapel.credits[label] : (label === 'Removed' ? 0 : 1)
          let updated = false
          for (const { _id, count } of data) {
            if (label === _id) {
              statsArr.value.splice(i, 1, { label, value, count })
              updated = true
              break
            }
          }
          if (!updated) statsArr.value.splice(i, 1, { label, value, count: 0 })
        }
      }
    })

    function stringFormatDate (date) {
      const dt = new Date(date)
      const time = dt.toLocaleTimeString()
      const dateStr = dt.toDateString()
      return dateStr.substring(0, 3) + ',' + dateStr.substring(3, 7) + ',' + dateStr.substring(10) + ' at ' + time.substring(0, 5) + time.substring(-3)
    }
    const isSaving = ref(false)
    async function save () {
      isSaving.value = true
      let dt = new Date(date.value + ' ' + time.value)
      let obj = {
        date: dt,
        speaker: speaker.value,
        title: title.value,
        series: series.value,
        links: links.value
      }
      // Look to see if any changes were made to credits; if so, update all of the students who received those credits to the new value
      for (const { label, value } of statsArr.value) {
        if (!props.chapel.credits || !(label in props.chapel.credits) || props.chapel.credits[label] !== value) {
          obj['credits.' + label] = value
          updateStudentCredits({ chapelId: props.chapel._id, type: label, value })
        }
      }

      if ('_id' in props.chapel && props.chapel._id !== '') {
        try {
          await root.$feathers.service('chapel/schedule').patch(props.chapel._id, obj)
          isSaving.value = false
          active.value = false
        } catch (err) {
          alert('Error updating chapel: ' + err)
        }
      } else {
        try {
          let { data } = await root.$feathers.service('system/term').find({ query: { start: { $lte: dt }, end: { $gte: dt }, type: 'Traditional' } })
          if (data.length === 0) {
            alert('Error: There is no traditional term that covers the date that you entered. Please verify this date and, if it is correct and during a traditional fall or spring term, contact Tech Services so they can troubleshoot the issue.')
          } else {
            obj.term = data[0].term
            obj.termDesc = data[0].label
            await root.$feathers.service('chapel/schedule').create(obj)
            isSaving.value = false
            active.value = false
          }
        } catch (err) {
          alert('Error creating chapel: ' + err)
        }
      }
      isSaving.value = false
    }
    const removeDialog = ref(false)
    function remove () {
      root.$feathers.service('chapel/schedule').remove(props.chapel._id).catch((err) => {
        alert('Error removing chapel: ' + err)
      })
      removeDialog.value = false
      active.value = false
      emit('updated')
    }

    const addLinkDialog = ref(false)
    const newLink = ref({
      type: '',
      label: '',
      link: '',
      visible: false,
      loginRequired: false
    })
    const editLinkIndex = ref(null)

    function closeLinkDialog () {
      addLinkDialog.value = false
      newLink.value = {
        type: '',
        label: '',
        link: '',
        visible: false,
        loginRequired: false
      }
    }

    function editLink (index) {
      editLinkIndex.value = index
      newLink.value = { ...links.value[index] }
      addLinkDialog.value = true
    }

    function addLink () {
      if (editLinkIndex.value != null) {
        links.value.splice(editLinkIndex.value, 1, { ...newLink.value })
        editLinkIndex.value = null
      } else {
        links.value.push({ ...newLink.value })
      }
      closeLinkDialog()
    }
    function removeLink () {
      links.value.splice(editLinkIndex.value, 1)
      editLinkIndex.value = null
      closeLinkDialog()
    }

    return {
      active,
      tab,
      dateMenu,
      date,
      timeOptions,
      time,
      speaker,
      title,
      series,
      isComplete,
      links,
      linkCategories,
      statsArr,
      stringFormatDate,
      isSaving,
      save,
      removeDialog,
      remove,
      addLinkDialog,
      newLink,
      editLinkIndex,
      closeLinkDialog,
      editLink,
      addLink,
      removeLink
    }
  }
}
</script>
