var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.chapel._id ? 'Edit' : 'Add')+" Chapel")]),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Chapel Details")]),_c('v-tab',[_vm._v("Links")]),_c('v-tab',[_vm._v("Attendance Stats/Credit Values")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{staticStyle:{"min-height":"350px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Chapel Date","prepend-icon":"fal fa-calendar-day","outlined":"","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.timeOptions,"label":"Chapel Time","prepend-icon":"fal fa-clock","outlined":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1),_c('v-text-field',{attrs:{"label":"Speaker","prepend-icon":"fal fa-podium","outlined":""},model:{value:(_vm.speaker),callback:function ($$v) {_vm.speaker=$$v},expression:"speaker"}}),_c('v-text-field',{attrs:{"label":"Title","prepend-icon":"fal fa-comment-lines","outlined":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-text-field',{attrs:{"label":"Series","prepend-icon":"fal fa-users-class","outlined":""},model:{value:(_vm.series),callback:function ($$v) {_vm.series=$$v},expression:"series"}})],1)],1)],1),_c('v-tab-item',[_c('v-card',{staticStyle:{"min-height":"350px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-list',_vm._l((_vm.links),function(ref,index){
var type = ref.type;
var label = ref.label;
var link = ref.link;
var visible = ref.visible;
var loginRequired = ref.loginRequired;
return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.editLink(index)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Label: "+_vm._s(label))]),_c('v-list-item-subtitle',[_vm._v("Links to: "+_vm._s(link))]),_c('v-list-item-subtitle',[_vm._v("Category: "+_vm._s(type))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-eye"+_vm._s(visible ? '' : '-slash'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(visible ? 'Is' : 'Is not')+" visible to anyone but admins")])])],1),(loginRequired)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-user"+_vm._s(loginRequired ? '' : '-slash'))])]}}],null,true)},[_c('span',[_vm._v("Login "+_vm._s(loginRequired ? 'is' : 'is not')+" required")])])],1):_vm._e()],1)}),1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_vm._v("Add Link")])]}}]),model:{value:(_vm.addLinkDialog),callback:function ($$v) {_vm.addLinkDialog=$$v},expression:"addLinkDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.editLinkIndex ? 'Edit' : 'Add')+" Link")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":6}},[_c('v-combobox',{attrs:{"items":_vm.linkCategories,"label":"Link Category","outlined":""},model:{value:(_vm.newLink.type),callback:function ($$v) {_vm.$set(_vm.newLink, "type", $$v)},expression:"newLink.type"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":6}},[_c('v-text-field',{attrs:{"label":"Label for link","outlined":""},model:{value:(_vm.newLink.label),callback:function ($$v) {_vm.$set(_vm.newLink, "label", $$v)},expression:"newLink.label"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":12}},[_c('v-text-field',{attrs:{"label":"Link","outlined":"","hide-details":""},scopedSlots:_vm._u([(_vm.newLink.link !== '')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"href":_vm.newLink.link,"target":"_blank"}},on),[_c('v-icon',[_vm._v("fal fa-external-link")])],1)]}}],null,false,3276213353)},[_c('span',[_vm._v("Open Link")])])]},proxy:true}:null],null,true),model:{value:(_vm.newLink.link),callback:function ($$v) {_vm.$set(_vm.newLink, "link", $$v)},expression:"newLink.link"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":6}},[_c('v-checkbox',{attrs:{"label":"Visible to users","outlined":""},model:{value:(_vm.newLink.visible),callback:function ($$v) {_vm.$set(_vm.newLink, "visible", $$v)},expression:"newLink.visible"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":6}},[(_vm.newLink.visible)?_c('v-checkbox',{attrs:{"label":"Login required to view"},model:{value:(_vm.newLink.loginRequired),callback:function ($$v) {_vm.$set(_vm.newLink, "loginRequired", $$v)},expression:"newLink.loginRequired"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeLinkDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.newLink.type === '' || _vm.newLink.label === '' || _vm.newLink.link === '',"text":"","color":"success"},on:{"click":_vm.addLink}},[_vm._v("Add Link")]),_c('v-spacer'),(_vm.editLinkIndex != null)?_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.removeLink}},[_vm._v("Remove Link")]):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{staticStyle:{"min-height":"350px"},attrs:{"flat":""}},[_c('v-data-table',{attrs:{"items":_vm.statsArr,"headers":[{text:'Credit Type',value:'label'},{text:'Credit Value',value:'value'},{text:'Count',value:'count'},{text:'Action',value:'action'}],"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] }},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){item.value--}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-minus")])],1),_vm._v(" "+_vm._s(item.value)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){item.value++}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-plus")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.count > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":'/chapel/students/' + _vm.chapel._id + '/' + item.label,"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View all students who had a \""+_vm._s(item.label)+"\"\" credit for this chapel")])]):_vm._e()]}}])})],1)],1)],1),(_vm.isSaving)?_c('v-card-actions',[_c('v-alert',{attrs:{"color":"info"}},[_vm._v("Saving...")])],1):_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.active = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-spacer'),(_vm.isComplete)?_c('v-btn',{attrs:{"to":'/chapel/students/' + _vm.chapel._id}},[_vm._v("View Students")]):_vm._e(),_c('v-spacer'),(_vm.chapel._id)?_c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"error"}},on),[_vm._v("Remove")])]}}],null,false,3208429363),model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Please Confirm")]),_c('v-card-text',[_c('p',[_vm._v("Are you sure you want to remove this chapel?")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.remove}},[_vm._v("Remove Chapel")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }